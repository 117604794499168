import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TextButton } from '@/core/TextButton.atom';
import { Alert, Modal } from 'react-bootstrap';
import { NotificationsDetails } from '@/notifications/NotificationsDetails';
import { doTrack } from '@/track/track.service';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import {
  allEmailsValid,
  fetchConditionMonitor,
  hasRecipients,
  newConditionMonitorNotification,
  recipientsToInput,
  saveConditionMonitorNotificationConfiguration,
  saveNotificationFieldTracking,
  updateNotificationFieldTracking,
  validateEmails,
} from '@/notifications/notifications.utilities';
import { TooltipButton } from '@/core/TooltipButton.atom';
import {
  ConditionMonitorInputV1,
  ConditionMonitorNotificationConfigurationInputV1,
  ConditionMonitorOutputV1,
  NotificationConfigurationOutputV1,
  sqConditionMonitorsApi,
} from '@/sdk';
import { formatApiError } from '@/utilities/utilities';
import { TItemProperties } from '@/tools/itemProperties/hooks/useProperties';
import { ConfirmDeleteModal } from '@/core/ConfirmDeleteModal.molecule';
import { Icon } from '@/core/Icon.atom';
import { NotificationEditingContext } from '@/notifications/notifications.constants';
import { getDependencies } from '@/utilities/formula.utilities';
import { updateConditionNotificationIds } from '@/notifications/notifications.actions';

export interface NotificationsConfigureModalProps {
  conditionMonitorId?: string;
  condition?: TItemProperties;
  closeModal: (refresh?: boolean) => void;
  context: NotificationEditingContext;
}

export const NotificationsConfigureModal: React.FunctionComponent<NotificationsConfigureModalProps> = ({
  conditionMonitorId,
  condition,
  closeModal,
  context,
}) => {
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState(false);
  const [conditionMonitor, setConditionMonitor] = useState<ConditionMonitorOutputV1>();
  const conditionMonitorUnmodified = useRef<ConditionMonitorOutputV1>();

  const [notificationConfiguration, setNotificationConfiguration] = useState<NotificationConfigurationOutputV1>();
  const notificationConfigurationUnmodified = useRef<NotificationConfigurationOutputV1>();

  const [conditionNames, setConditionNames] = useState<Record<string, string>>({});
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const isNew = _.isEmpty(conditionMonitor?.id);
  const [isEditMode, setIsEditMode] = useState(_.isEmpty(conditionMonitorId));

  const saveButtonText = isNew ? 'NOTIFICATIONS.MODAL.CREATE_NOTIFICATION' : 'SAVE';
  const saveButtonTooltipText = isNew ? 'NOTIFICATIONS.MODAL.TOOLTIP.SAVE' : 'NOTIFICATIONS.MODAL.TOOLTIP.UPDATE';
  const canSave =
    !isSaving &&
    !_.isEmpty(conditionMonitor?.name) &&
    hasRecipients(notificationConfiguration) &&
    allEmailsValid(validateEmails(notificationConfiguration));

  useEffect(() => {
    if (conditionMonitorId) {
      try {
        fetchConditionMonitor(conditionMonitorId).then(
          ({ conditionMonitor, notificationConfiguration, conditionNames }) => {
            setConditionMonitor(conditionMonitor);
            conditionMonitorUnmodified.current = conditionMonitor;
            setNotificationConfiguration(notificationConfiguration);
            notificationConfigurationUnmodified.current = notificationConfiguration;
            setConditionNames(conditionNames);
          },
        );
      } catch (e) {
        errorToast({ httpResponseOrError: e, displayForbidden: true });
        closeModal();
      }
    } else if (condition) {
      getDependencies({ id: condition.id }).then((result) => {
        const { conditionMonitor, notificationConfiguration, conditionNames } = newConditionMonitorNotification(
          condition,
          result.assets.length > 0,
        );
        setConditionMonitor(conditionMonitor);
        conditionMonitorUnmodified.current = conditionMonitor;
        setNotificationConfiguration(notificationConfiguration);
        notificationConfigurationUnmodified.current = notificationConfiguration;
        setConditionNames(conditionNames);
      });
    } else {
      throw TypeError('Must provide condition or condition monitor id');
    }
  }, []);

  const onClickSave = async () => {
    doTrack('Notification', 'Save', isNew ? 'Created' : 'Updated');

    if (
      !conditionMonitor ||
      !notificationConfiguration ||
      !conditionMonitorUnmodified.current ||
      !notificationConfigurationUnmodified.current
    ) {
      return;
    }

    isNew
      ? saveNotificationFieldTracking(conditionMonitor, notificationConfiguration)
      : updateNotificationFieldTracking(
          conditionMonitor,
          conditionMonitorUnmodified.current,
          notificationConfiguration,
          notificationConfigurationUnmodified.current,
        );

    const conditionMonitorInput: ConditionMonitorInputV1 = {
      name: conditionMonitor.name,
      conditionIds: conditionMonitor.conditionIds,
      queryRangeLookAhead: conditionMonitor.queryRangeLookAhead,
      cronSchedule: [],
      enabled: conditionMonitor.enabled,
    };
    const notificationConfigurationInput: ConditionMonitorNotificationConfigurationInputV1 = {
      toEmailRecipients: recipientsToInput(notificationConfiguration.toEmailRecipients),
      ccEmailRecipients: recipientsToInput(notificationConfiguration.ccEmailRecipients),
      bccEmailRecipients: recipientsToInput(notificationConfiguration.bccEmailRecipients),
      capsuleGrouping: notificationConfiguration.capsuleGrouping,
      capsuleProperties: notificationConfiguration.capsuleProperties,
      contextualText: notificationConfiguration.contextualText,
      timezone: notificationConfiguration.timezone,
    };
    setIsSaving(true);
    try {
      await saveConditionMonitorNotificationConfiguration(
        conditionMonitorInput,
        notificationConfigurationInput,
        conditionMonitor.id,
      );
      successToast({ messageKey: 'NOTIFICATIONS.MODAL.SAVED' });
      closeModal(true);
    } catch (e) {
      setErrorMessage(formatApiError(e as Error));
    } finally {
      setIsSaving(false);
    }
  };

  const onClickDelete = async () => {
    if (isNew || !conditionMonitor) {
      return;
    }

    await sqConditionMonitorsApi.archiveConditionMonitor({ id: conditionMonitor.id });
    updateConditionNotificationIds(conditionMonitor.conditionIds);
    closeModal(true);
    successToast({
      messageKey: 'TRASH.ITEM_TRASHED_NOTIFICATION',
      messageParams: { ITEM_NAME: conditionMonitor.name },
    });
    doTrack('Notification', 'Existing notification', 'Deleted');
  };

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const modalTitle = () => {
    if (isEditMode) {
      return isNew ? (
        <>
          {t('NOTIFICATIONS.MODAL.HEADER_CREATE')}
          <TooltipButton
            tooltipKBLink="https://support.seeq.com/space/KB/2594111502/Notifications+on+Conditions"
            tooltipText={t('NOTIFICATIONS.MODAL.TOOLTIP.CLICK_FOR_INFO')}
          />
        </>
      ) : (
        <>{t('NOTIFICATIONS.MODAL.HEADER_EDIT')}</>
      );
    }

    return (
      <div className="flexColumnContainer">
        {t('NOTIFICATIONS.MODAL.HEADER_DETAILS')}
        <div className="ml15 cursorPointer" data-testid="edit-notification-pencil" onClick={toggleEditMode}>
          <Icon icon="fa-pencil" extraClassNames="mr10" />
        </div>
      </div>
    );
  };

  const modalHeader = () => (
    <div className="flexColumnContainer flexFill flexSpaceBetween">
      <div className="flexColumnContainer">
        <Modal.Title>{modalTitle()}</Modal.Title>
      </div>
    </div>
  );

  const deleteButton = () => (
    <TextButton
      testId="deleteButton"
      extraClassNames="mr15"
      variant="danger"
      onClick={() => setShowConfirmDeleteModal(true)}
      label="DELETE"
    />
  );

  const modalFooter = () => (
    <>
      {!isNew && isEditMode && deleteButton()}
      <div className="flexFill" />

      {isEditMode && (
        <>
          <TextButton testId="cancelButton" extraClassNames="btn btn-default" onClick={closeModal} label="CANCEL" />
          <TextButton
            label={saveButtonText}
            testId="saveOrNextButton"
            extraClassNames="ml15"
            variant="theme"
            onClick={onClickSave}
            disabled={!canSave}
            iconStyle="inherit"
            tooltip={saveButtonTooltipText}
            tooltipOptions={{ placement: 'top' }}
          />
        </>
      )}
      {!isEditMode && (
        <TextButton
          label={t('CLOSE')}
          testId="closeButton"
          extraClassNames="ml15"
          variant="theme"
          onClick={closeModal}
          iconStyle="inherit"
          tooltipOptions={{ placement: 'top' }}
        />
      )}
    </>
  );

  return (
    <>
      <Modal
        show={true}
        onHide={closeModal}
        animation={false}
        scrollable={true}
        data-testid="notificationsModalConfigure"
        // Allows CKEditor to work in the modal (https://github.com/ckeditor/ckeditor5/issues/1409)
        enforceFocus={false}>
        <Modal.Header closeButton={true}>{modalHeader()}</Modal.Header>

        <Modal.Body>
          {errorMessage && (
            <Alert className="p10 mb10" transition={false} variant="danger">
              {errorMessage}
            </Alert>
          )}
          {conditionMonitor && notificationConfiguration && (
            <NotificationsDetails
              isEditMode={isEditMode}
              conditionMonitor={conditionMonitor}
              notificationConfiguration={notificationConfiguration}
              conditionNames={conditionNames}
              setConditionMonitor={setConditionMonitor}
              setNotificationConfiguration={setNotificationConfiguration}
              context={context}
            />
          )}
        </Modal.Body>

        <Modal.Footer>{modalFooter()}</Modal.Footer>
      </Modal>

      {showConfirmDeleteModal && (
        <ConfirmDeleteModal onClose={() => setShowConfirmDeleteModal(false)} action={onClickDelete} />
      )}
    </>
  );
};
